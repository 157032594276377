import { AuthenticationActions } from 'react-aad-msal';
import { createAsyncAction } from 'typesafe-actions';
import axios from 'axios';
import { UserState } from './types';
import { makeError } from '../error/actions';
import { AppState } from '..';
import { Dispatch } from 'redux';

export const userRequest = createAsyncAction("USER_REQUEST_START", "USER_REQUEST_SUCCESS", "USER_REQUEST_FAILURE")<void, UserState, UserState>();
export const userDomainRequest = createAsyncAction("USER_DOMAIN_REQUEST_START", "USER_DOMAIN_REQUEST_SUCCESS", "USER_DOMAIN_REQUEST_FAILURE")<void, UserState, UserState>();
export const authenticatedUser = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {

    dispatch(userRequest.request());

    axios.get('/api/v1/diagnostics/user')
      .then((response) => {
        dispatch(userRequest.success({ userName: response.data.userName, isAdmin: response.data.isAdmin, userDomain: ['', ''] }));
        //dispatch(userRequest.success(response.data));
      })
      .catch(function (error) {
        dispatch(makeError(error));
        dispatch(userRequest.failure({ userName: "<not logged in>", isAdmin: false, userDomain: ['', ''] }));
      });
    axios.get('/api/v1/diagnostics')
      .then((response) => {
        dispatch(userDomainRequest.success({ userDomain: response.data }));
        //dispatch(userRequest.success(response.data));
      })
      .catch(function (error) {
        dispatch(makeError(error));
        dispatch(userDomainRequest.failure({ userName: "<not logged in>", isAdmin: false, userDomain: ['', ''] }));
      });
  }
}

export const userDomain = () => {
  return (dispatch: Dispatch, getState: () => AppState) => {

  }
}

export const fakeUser = () => (dispatch: any) => {
  const dummyUser = 'developer@unilever.com';
  dispatch({
    type: AuthenticationActions.LoginSuccess,
    payload: { account: { userName: dummyUser } }
  });
};
